import React from 'react';
import Layout from '../components/layout/Layout';
//import { Link } from 'gatsby';
import { graphql } from "gatsby";
import { Trans, Link, useI18next } from 'gatsby-plugin-react-i18next';

const PrivacyPolicy = ({
  location,
}) => (
  <Layout location={location}>
    <div className="container mx-auto px-5 max-w-2xl">
      <div className="py-10 text-4xl text-center">Politique de confidentialité</div>
      <div className="py-5 text-gray-600 italic">En vigueur au 15 avril 2022</div>
      <div className="py-5 text-xl text-primary">Article 1 : Collecte de l’information</div>
      <div className="py-2 text-gray-800">
        Nous recueillons des informations vous concernant lorsque vous indiquez votre adresse électronique, votre nom ou votre prénom au sein du Site internet https://cephalomax.com
      </div>
      <div className="py-2 text-gray-800">
        Nous recevons et enregistrons également automatiquement des informations à partir de votre ordinateur, votre téléphone et navigateur comme votre adresse IP ou la page que vous demandez.	
      </div>
      <div className="py-2 text-gray-800">
        Aucune donnée personnelle n'est collectée par ce site internet via les cookies sans votre consentement. Une fois votre consentement donné, il est valide au maximum 13 mois. Vous pouvez changer d'avis en cliquant sur le bouton "Gestion des cookies". 
      </div>
      <div className="py-2 text-gray-800">
        Les données des formulaires de ce site ne sont pas enregistrées sans votre consentement. Elles peuvent être transmises par courriel au gestionnaire du site. Une suppression ou une pseudonymisation est réalisée automatiquement après 13 mois. 
      </div>
      <div className="py-5 text-xl text-primary">Article 2 : Utilisation des informations</div>
      <div className="py-2 text-gray-800">
        Toutes les informations que nous recueillons auprès de vous ont pour seuls buts :
      </div>
      <ul className="list-disc list-inside py-2 text-gray-800 pl-5">
        <li>D’améliorer le fonctionnement du site et l’expérience utilisateur ;</li>
        <li>De vous recontacter dans le cadre de l’activité du Site ;</li>
        <li>De vous transmettre, le cas échéant, des newsletters par courriel.</li>
      </ul>
      <div className="py-5 text-xl text-primary">Article 3 : Confidentialité de vos données</div>
      <div className="py-2 text-gray-800">
        Nous sommes les seuls destinataires des informations recueillies sur le Site https://cephalomax.com. 
      </div>
      <div className="py-2 text-gray-800">
        Vos informations personnelles ne seront ni vendues, ni échangées, ni transférées, ni données à une autre société pour quelque raison qu’il soit, sans votre consentement, en dehors de ce qui est nécessaire pour répondre à une de vos demandes.
      </div>

      <div className="py-5 text-xl text-primary">
        Article 4 : Droit d’accès, de rectification et de suppression des données personnelles
      </div>
      <div className="py-2 text-gray-800">
        En vertu de la loi Informatique et Libertés, en date du 6 janvier 1978 et de la section III du Règlement général sur la protection des données (RGPD), l’Utilisateur dispose d’un droit d’accès, de rectification, de limitation, de portabilité, de suppression et d’opposition de ses données personnelles.
      </div>
      <div className="py-2 text-gray-800">
        L’Utilisateur exerce ce droit via l’adresse : <a href="mailto:contact@cephalomax.com">contact@cephalomax.com</a>
      </div>

      <div className="py-5 text-xl text-primary">
        Article 5 : Utilisation de cookies
      </div>
      <div className="py-2 text-gray-800">
        Afin de mieux comprendre nos Utilisateurs, nous utilisons des cookies. Un cookie enregistre des informations relatives à la navigation de votre ordinateur sur notre Site (les pages que vous avez consultées, la date et l’heure de la consultation, etc.) que nous pourrons lire lors de vos visites ultérieures.
      </div>
      <div className="py-2 text-gray-800">
        L’utilisation de certains cookies techniques sont nécessaires au fonctionnement du Site.
      </div>
      <div className="py-2 text-gray-800">
        Ils facilitent votre accès et vous aident à naviguer sur notre Site et à utiliser toutes ses fonctionnalités fondamentales. Sans ces cookies, notre site Internet ne fonctionnerait pas correctement et vous ne pourriez pas utiliser certaines de ses fonctionnalités importantes.
      </div>
      <div className="py-2 text-gray-800">
        La durée de conservation de ces informations est de douze (12) mois.
      </div>
      <div className="py-2 text-gray-800">
        L’Utilisateur est informé que lors de ses visites sur le Site https://cephalomax.com un cookie peut s’installer automatiquement sur son logiciel de navigation.
      </div>
      <div className="py-2 text-gray-800">
        En naviguant sur le Site https://cephalomax.com il les accepte. 
      </div>

      <div className="py-5 text-xl text-primary">
        Article 6 : Consentement
      </div>
      <div className="py-2 text-gray-800">
        En utilisant le Site https://cephalomax.com, vous consentez à notre politique de confidentialité.
      </div>
      <div className="py-2 text-gray-800">
        Pour plus d’informations, vous pouvez retrouver nos mentions légales et nos conditions d’utilisation du Site à ces adresses :
      </div>
      <ul className="list-none list-inside py-2 text-gray-800 pl-5">
        <li>Mentions légales: <Link to="/legal-notice">https://cephalomax.com/legal-notice</Link></li>
        <li>Conditions générales d'utilisation: <Link to="/term-and-conditions-of-use">https://cephalomax.com/term-and-conditions-of-use</Link></li>
      </ul>
      <div className="py-5 text-xl text-primary">
        Article 7 : Modifications de notre politique de confidentialité
      </div>
      <div className="py-2 text-gray-800">
        Le Site https://cephalomax.com peu modifier ou actualiser cette déclaration de confidentialité.
      </div>
      <div className="py-2 text-gray-800">
        Le cas échéant, l’utilisateur en sera tenu informé. Lorsque les modifications apportées à cette déclaration de confidentialité ont un impact fondamental sur la nature du traitement ou sur votre utilisation, un préavis suffisant vous donnera la possibilité d’exercer vos droits.
      </div>

      <div className="py-5 text-xl text-primary">
        Article 8 : Propriété intellectuelle
      </div>
      <div className="py-2 text-gray-800">
        Le site Web est protégé par les droits de propriété intellectuelle et est la propriété exclusive de CEPHALOMAX.
      </div>
      <div className="py-2 text-gray-800">
        La conception, le graphisme, le contenu, l'organisation de ce site sont des œuvres originales et sont l'entière propriété de CEPHALOMAX.
      </div>
      <div className="py-2 text-gray-800">
        Toute reproduction, ou démarquage, total ou partiel, fait sans le consentement de l'auteur, ou de ses ayants droit, ou de ses ayants cause est illicite. Il en est de même pour la traduction, l'adaptation, l'arrangement par quelque procédé que ce soit.
      </div>
    </div>
    
  </Layout>
);

export default PrivacyPolicy;

export const query = graphql`
  query {
    locales: allLocale{
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`